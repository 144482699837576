import { fetchError, fetchStart, fetchSuccess } from '../../../redux/actions';
import { setAuthUser, setForgetPassMailSent, updateLoadUser } from '../../../redux/actions/Auth';
import React from 'react';
import axios from './config';
import crypto from 'crypto';
import pbkdf2 from 'pbkdf2';

const JWTAuth = {
  onRegister: ({ name, email, password }) => {
    return dispatch => {
      dispatch(fetchStart());
      axios
        .post('auth/register', {
          email: email,
          password: password,
          name: name,
        })
        .then(({ data }) => {
          if (data.result) {
            localStorage.setItem('token', data.token.access_token);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.token.access_token;
            dispatch(fetchSuccess());
            dispatch(JWTAuth.getAuthUser(true, data.token.access_token));
          } else {
            dispatch(fetchError(data.error));
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.message));
        });
    };
  },

  onLogin: ({ phone, password }) => {
    return dispatch => {
      try {
        dispatch(fetchStart());
        const intermediateKey = crypto.pbkdf2Sync(password, '', 1024, 32, 'sha256').toString('hex');
        axios
          .post('/login', {
            contact_number: phone,
            password: intermediateKey,
          })
          .then(({ data }) => {
            if (data.result) {
              localStorage.setItem('token', data.token.access_token);
              axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.token.access_token;
              dispatch(fetchSuccess());
              dispatch(JWTAuth.getAuthUser(true, data.token.access_token));
            } else {
              dispatch(fetchError(data.error));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },
  onLogout: () => {
    return dispatch => {
      dispatch(fetchStart());
      localStorage.removeItem('token');
      dispatch(fetchSuccess());
      window.location = '/signin';
    };
  },

  updatePassword: (password, callbackFn) => {
    return dispatch => {
      dispatch(fetchStart());
      const intermediateKey = crypto.pbkdf2Sync(password, '', 1024, 32, 'sha256').toString('hex');
      axios
        .post('/user/update/password', {
          new_password: intermediateKey,
        })
        .then(({ data }) => {
          if (data.result) {
            dispatch(fetchSuccess());
            if (callbackFn) callbackFn();
          } else {
            dispatch(fetchError('Server Error ' + data.error));
          }
        })
        .catch(err => {
          dispatch(fetchError('Server error'));
        });
    };
  },

  updateProfile: (firstName, lastName, callbackFn) => {
    return dispatch => {
      dispatch(fetchStart());
      axios
        .post('/user/update', {
          first_name: firstName,
          last_name: lastName,
        })
        .then(({ data }) => {
          if (data.result) {
            dispatch(fetchSuccess());
            if (callbackFn) callbackFn();
          } else {
            dispatch(fetchError('Server Error ' + data.error));
          }
        })
        .catch(err => {
          dispatch(fetchError('Server error'));
        });
    };
  },

  getAuthUser: (loaded = false, token) => {
    return dispatch => {
      if (!token) {
        const token = localStorage.getItem('token');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      }
      dispatch(fetchStart());
      dispatch(updateLoadUser(loaded));
      axios
        .get('/auth/me')
        .then(({ data }) => {
          if (data.result) {
            dispatch(fetchSuccess());
            dispatch(setAuthUser(data));
          } else {
            dispatch(updateLoadUser(true));
          }
        })
        .catch(function(error) {
          dispatch(updateLoadUser(true));
        });
    };
  },

  onForgotPassword: () => {
    return dispatch => {
      dispatch(fetchStart());

      setTimeout(() => {
        dispatch(setForgetPassMailSent(true));
        dispatch(fetchSuccess());
      }, 300);
    };
  },
  getSocialMediaIcons: () => {
    return <React.Fragment> </React.Fragment>;
  },
};

export default JWTAuth;
