import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import PageLoader from '../../@jumbo/components/PageComponents/PageLoader';

const Dashboards = ({ match }) => {
  const requestedUrl = match.url.replace(/\/$/, '');
  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        <Route path={`${requestedUrl}/device/:id/historical-data`} component={lazy(() => import('./HistoricalData'))} />
        <Redirect exact from={`${requestedUrl}/`} to={`${requestedUrl}/device/0`} />
        <Route path={`${requestedUrl}/device/:id`} component={lazy(() => import('./Device'))} />
        <Route component={lazy(() => import('../ExtraPages/404'))} />
      </Switch>
    </Suspense>
  );
};

export default Dashboards;
