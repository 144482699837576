import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import Dashboards from './Dashboards';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import UpdatePassword from './Auth/UpdatePassword';
import UpdateProfile from './Auth/UpdateProfile';
import Login from './Auth/Login';
// import LayoutBuilder from './LayoutBuilder';

const RestrictedRoute = ({ component: Component, ...rest }) => {
  const { authUser } = useSelector(({ auth }) => auth);
  return (
    <Route
      {...rest}
      render={props =>
        authUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const Routes = () => {
  const { authUser } = useSelector(({ auth }) => auth);
  const location = useLocation();

  if (location.pathname === '' || location.pathname === '/') {
    return <Redirect to={'/dashboard'} />;
  } else if (authUser && location.pathname === '/signin') {
    return <Redirect to={'/dashboard'} />;
  }

  return (
    <React.Fragment>
      <Switch>
        <RestrictedRoute path="/dashboard" component={Dashboards} />
        <RestrictedRoute path="/update-profile" component={UpdateProfile} />
        <RestrictedRoute path="/update-password" component={UpdatePassword} />
        <Route path="/signin" component={Login} />
        {/*<Route path="/layout-builder" component={LayoutBuilder} />*/}
      </Switch>
    </React.Fragment>
  );
};

export default Routes;
