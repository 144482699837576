import { UPDATE_DEVICE_DETAIL, GET_DEVICE_READING } from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  wattReadings: [],
  todayGeneration: 0,
  todayConsumption: 0,
  dailyAggregate: [],
  monthlyAggregate: [],
  liveGeneration: 0,
  liveConsumption: 0,
  presentYear: '',
  presentMonth: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DEVICE_READING: {
      const {
        watt_readings: wattReadings,
        today_generation: todayGeneration,
        today_consumption: todayConsumption,
        daily_aggregate_readings: dailyAggregate,
        monthly_aggregate_readings: monthlyAggregate,
        present_month: presentMonth,
        present_year: presentYear,
      } = action.payload;
      const liveData =
        wattReadings.length !== 0
          ? wattReadings.slice(-1)[0]
          : { generation_watt: null, consumption_watt: null, reading_timestamp: null };
      const { generation_watt: liveGeneration, consumption_watt: liveConsumption } = liveData;
      const lastUpdated = liveData.reading_timestamp;
      return {
        wattReadings,
        todayGeneration,
        todayConsumption,
        dailyAggregate,
        monthlyAggregate,
        liveGeneration,
        liveConsumption,
        presentYear,
        presentMonth,
        lastUpdated,
      };
    }
    default:
      return state;
  }
};
