import {
  GET_DEVICE_READING_ON_DATE,
  GET_DEVICE_READING_ON_DATE_RANGE,
  SET_DAY_ENERGY_PROFILE_SELECTED_DATE,
  SET_COMPARATIVE_DAYWISE_FROM_DATE,
  SET_COMPARATIVE_DAYWISE_TO_DATE,
  SET_COMPARATIVE_DAYWISE_METERING_TYPE,
  SET_COMPARATIVE_DAYWISE_FETCH_START,
  SET_COMPARATIVE_DAYWISE_FETCH_END,
} from '../../@jumbo/constants/ActionTypes';

// const today = new Date();
// const todayStr = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

const INIT_STATE = {
  dayEnergyProfile: {
    selectedDate: new Date(Date.now() - 864e5),
    wattReadings: [],
    totalGeneration: 0,
    totalConsumption: 0,
  },
  comparativeDaywiseData: {
    dailyAggregate: [],
    fromDate: new Date(),
    toDate: new Date(),
    isNetMeteringForSolarHours: false,
    isLoading: false,
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DEVICE_READING_ON_DATE: {
      const {
        watt_readings_on_date: wattReadings,
        total_generation_on_date: totalGeneration,
        total_consumption_on_date: totalConsumption,
      } = action.payload;

      return {
        ...state,
        dayEnergyProfile: {
          ...state.dayEnergyProfile,
          wattReadings,
          totalGeneration,
          totalConsumption,
        },
      };
    }
    case GET_DEVICE_READING_ON_DATE_RANGE: {
      const { daily_aggregate_readings: dailyAggregate } = action.payload;

      return {
        ...state,
        comparativeDaywiseData: {
          ...state.comparativeDaywiseData,
          dailyAggregate,
          isLoading: false,
        },
      };
    }
    case SET_DAY_ENERGY_PROFILE_SELECTED_DATE: {
      const { selectedDate } = action.payload;
      return {
        ...state,
        dayEnergyProfile: {
          ...state.dayEnergyProfile,
          selectedDate,
        },
      };
    }
    case SET_COMPARATIVE_DAYWISE_FROM_DATE: {
      const { fromDate } = action.payload;
      return {
        ...state,
        comparativeDaywiseData: {
          ...state.comparativeDaywiseData,
          fromDate,
        },
      };
    }
    case SET_COMPARATIVE_DAYWISE_TO_DATE: {
      const { toDate } = action.payload;
      return {
        ...state,
        comparativeDaywiseData: {
          ...state.comparativeDaywiseData,
          toDate,
        },
      };
    }
    case SET_COMPARATIVE_DAYWISE_METERING_TYPE: {
      const { isNetMeteringForSolarHours } = action.payload;
      return {
        ...state,
        comparativeDaywiseData: {
          ...state.comparativeDaywiseData,
          isNetMeteringForSolarHours,
        },
      };
    }
    case SET_COMPARATIVE_DAYWISE_FETCH_START: {
      return {
        ...state,
        comparativeDaywiseData: {
          ...state.comparativeDaywiseData,
          isLoading: true,
        },
      };
    }
    case SET_COMPARATIVE_DAYWISE_FETCH_END: {
      return {
        ...state,
        comparativeDaywiseData: {
          ...state.comparativeDaywiseData,
          isLoading: false,
        },
      };
    }
    default:
      return state;
  }
};
