import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import GridContainer from '../../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import AppTextInput from '../../../@jumbo/components/Common/formElements/AppTextInput';
import CmtAvatar from '../../../@coremat/CmtAvatar';
import { useDropzone } from 'react-dropzone';
import Button from '@material-ui/core/Button';
import CmtList from '../../../@coremat/CmtList';
import IconButton from '@material-ui/core/IconButton';
import AppSelectBox from '../../../@jumbo/components/Common/formElements/AppSelectBox';
import { emailNotValid, requiredMessage } from '../../../@jumbo/constants/ErrorMessages';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import { isValidEmail } from '../../../@jumbo/utils/commonHelper';
import { addNewUser, updateUser } from '../../../redux/actions/Users';
import { Container } from '@material-ui/core';
import JwtAuth from '../../../services/auth/jwt';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: 'relative',
    maxWidth: '800px',
  },
  dialogTitleRoot: {
    '& .MuiTypography-h6': {
      fontSize: 16,
      color: theme.palette.common.dark,
    },
  },
}));

const UpdateProfile = ({ open, onCloseDialog }) => {
  const classes = useStyles();
  const { user } = useSelector(({ auth }) => auth.authUser);
  const { first_name, last_name } = user;
  const [firstName, setFirstName] = useState(first_name);
  const [lastName, setLastName] = useState(last_name);

  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {}, [dispatch]);

  const onSubmitClick = () => {
    if (!firstName) setPasswordError(requiredMessage);
    if (!lastName) setConfirmPasswordError(requiredMessage);
    else onUpdateProfile(firstName, lastName);
  };

  const onUpdateProfile = (firstName, lastName) => {
    dispatch(
      JwtAuth.updateProfile(firstName, lastName, () => {
        window.location = '/';
      }),
    );
  };

  return (
    <Container className={classes.dialogRoot}>
      <DialogTitle className={classes.dialogTitleRoot}>{'Update Profile'}</DialogTitle>
      <DialogContent dividers>
        <Box mb={{ xs: 6, md: 5 }}>
          <AppTextInput
            fullWidth
            variant="outlined"
            label="First Name"
            value={firstName}
            onChange={e => {
              setFirstName(e.target.value);
              setPasswordError('');
            }}
            helperText={passwordError}
          />
        </Box>
        <Box mb={{ xs: 6, md: 5 }}>
          <AppTextInput
            fullWidth
            variant="outlined"
            label="Last Name"
            value={lastName}
            onChange={e => {
              setLastName(e.target.value);
              setConfirmPasswordError('');
            }}
            helperText={confirmPasswordError}
          />
        </Box>

        <Box display="flex" justifyContent="flex-end" mb={4}>
          <Button
            onClick={() => {
              window.location = '/';
            }}>
            Cancel
          </Button>
          <Box ml={2}>
            <Button variant="contained" color="primary" onClick={onSubmitClick}>
              Save
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Container>
  );
};

export default UpdateProfile;

UpdateProfile.prototype = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func,
};
