import React from 'react';
import { useSelector } from 'react-redux';

import PerfectScrollbar from 'react-perfect-scrollbar';
import makeStyles from '@material-ui/core/styles/makeStyles';

import CmtVertical from '../../../../../@coremat/CmtNavigation/Vertical';

const useStyles = makeStyles(() => ({
  perfectScrollbarSidebar: {
    height: '100%',
    transition: 'all 0.3s ease',
    '.Cmt-sidebar-fixed &, .Cmt-Drawer-container &': {
      height: 'calc(100% - 167px)',
    },
    '.Cmt-modernLayout &': {
      height: 'calc(100% - 72px)',
    },
    '.Cmt-miniLayout &': {
      height: 'calc(100% - 91px)',
    },
    '.Cmt-miniLayout .Cmt-sidebar-content:hover &': {
      height: 'calc(100% - 167px)',
    },
  },
}));

const SideBar = () => {
  const authUser = useSelector(({ auth }) => auth.authUser);
  let deviceMenus = [];
  if (authUser) {
    const devices = authUser.devices;
    deviceMenus = devices.map((device, index) => {
      return {
        name: device.device_name,
        type: 'item',
        link: `/dashboard/device/${index}`,
      };
    });
  }

  const Device = {
    name: 'Devices',
    type: 'section',
    children: deviceMenus,
  };

  const classes = useStyles();

  return (
    <PerfectScrollbar className={classes.perfectScrollbarSidebar}>
      <CmtVertical menuItems={[Device]} />
    </PerfectScrollbar>
  );
};

export default SideBar;
